<template>
  <div class="home-footer">
    <div class="home-footer-box">
      <div class="home-footer-lable">
        <div>
          <div>
            <img src="@/assets/img/zxq.png" alt="图片" />
            <p>找需求</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/ccj.png" alt="图片" />
            <p>促场景</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/zsj.png" alt="图片" />
            <p>助升级</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/st.png" alt="图片" />
            <p>构生态</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/sf.png" alt="图片" />
            <p>建示范</p>
          </div>
        </div>
      </div>
      <div class="home-footer-serve">
        <div>
          <div>
            <img src="@/assets/img/fwdh.png" alt="图片" />
            <p>7*12小时</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/fwvip.png" alt="图片" />
            <p>VIP一对一</p>
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/img/fwzq.png" alt="图片" />
            <p>全周期专业服务</p>
          </div>
        </div>
      </div>
      <div class="home-footer-link">
        <div class="home-footer-consult">
          <span>售前咨询热线</span>
          <div>
            <p><img src="@/assets/img/fodh.png" alt="图片" /> 400-025-6877</p>
            <p>
              <img src="@/assets/img/foyx.png" alt="图片" />
              IIG.RDD.honglian@chinasofti.com
            </p>
            <div>
              <div>
                <p
                  @mouseenter="codeImgIndex = 1"
                  @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/qywx.png" alt="图片" />企业微信
                </p>
                <p
                  @mouseenter="codeImgIndex = 2"
                  @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/fwh.png" alt="图片" />服务号
                </p>
                <p
                  @mouseenter="codeImgIndex = 3"
                  @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/gzh.png" alt="图片" />公众号
                </p>
              </div>
              <img
                src="@/assets/img/wxm.png"
                alt="二维码"
                v-show="codeImgIndex == 1"
              />
              <img
                src="@/assets/img/fwhm.png"
                alt="二维码"
                v-show="codeImgIndex == 2"
              />
              <img
                src="@/assets/img/gzhm.jpeg"
                alt="二维码"
                v-show="codeImgIndex == 3"
              />
            </div>
          </div>
        </div>
        <div class="home-footer-link-box">
          <div>
            <span>关于鸿联云</span>
            <p @click="$router.push('/solution')">解决方案</p>
            <p @click="$router.push('/replace')">loT硬件</p>
            <p @click="$router.push('/result')">应用成果</p>
            <p @click="$router.push('/activity')">培训活动</p>
            <p @click="$router.push('/policy')">政策资讯</p>
          </div>
          <div>
            <span>支持与服务</span>
            <p @click="consultShowBtn">服务咨询</p>
            <p @click="userUrl">国产化迁移咨询</p>
          </div>
          <div>
            <span>热门方案</span>
            <template v-if="categoryData != ''">
              <p
                v-for="item in categoryData[1].children"
                :key="item.id"
                @click="searchType(categoryData[1].id, item.id)"
              >
                {{ item.name }}
              </p>
            </template>
          </div>
          <div>
            <span>开鸿知识云</span>
            <p @click="$router.push('/replace')">IoT产品</p>
            <p @click="$router.push('/develop')">开发板</p>
          </div>
          <div>
            <span>快速链接</span>
            <p @click="toUrl('https://www.chinasofti.com/')">中软国际</p>
            <p @click="toUrl('https://www.jfh.com/')">解放号</p>
            <p @click="toUrl('https://www.kaihong.com/')">深开鸿</p>
            <p @click="toUrl('https://www.openatom.org/#/')">
              开放原子开源基金会
            </p>
          </div>
        </div>
      </div>
      <div class="home-footer-copyright">
        <span
          >Copyright © 2013- Copyright © 北京掌中无限信息技术有限公司 All rights
          reserved</span
        >
        <span>工信部备案号：京ICP备09083730号</span>
      </div>
    </div>
    <div class="home-footer-frame">
      <!--      挥手摇头-->
      <img
        v-show="this.jqrshow.jqr_yt"
        src="@/assets/img/jqr_yt.png"
        alt="jqr"
        class="udesk"
      />

      <!--      眨眼-->
      <img
        v-show="this.jqrshow.jqr_zy"
        src="@/assets/img/jqr_zy.png"
        alt="jqr"
        class="udesk"
      />

      <!--      旋转-->
      <img
        v-show="this.jqrshow.jqr_xz"
        src="@/assets/img/jqr_xz.png"
        alt="jqr"
        class="udesk"
      />

      <div
        @mouseenter="connectShowBtn(true)"
        @mouseleave="connectShowBtn(false)"
      >
        <a-icon type="customer-service" />
        <span>联系我们</span>
        <div
          class="home-floating-connect-box"
          v-if="connectShow"
          @mouseenter="connectShowBtn(true)"
          @mouseleave="connectShowBtn(false)"
        >
          <div>
            <div>
              <span>咨询热线</span>
              <strong>400-025-6877</strong>
            </div>
          </div>
          <div class="udesk customer">
            <div>
              <span class="customer-title">在线客服</span>
              <strong>专业人员在线解答您的疑问</strong>
            </div>
          </div>
          <div>
            <div>
              <span>咨询邮箱</span>
              <p>IIG.RDD.honglian@chinasofti.com</p>
            </div>
          </div>
          <div>
            <div class="home-footer-frame-wx">
              <div>
                <p
                  @mouseenter="codeImgIndex = 1"
                  @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/wx@2x.png" alt="图片" />企业微信
                </p>
                <p
                  @mouseenter="codeImgIndex = 2"
                  @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/fwh@2x.png" alt="图片" />服务号
                </p>
                <p
                  @mouseenter="codeImgIndex = 3"
                  @mouseleave="codeImgIndex = 1"
                >
                  <img src="@/assets/img/gzh@2x.png" alt="图片" />公众号
                </p>
              </div>
              <img
                src="@/assets/img/wxm.png"
                alt="二维码"
                v-show="codeImgIndex == 1"
              />
              <img
                src="@/assets/img/fwhm.png"
                alt="二维码"
                v-show="codeImgIndex == 2"
              />
              <img
                src="@/assets/img/gzhm.jpeg"
                alt="二维码"
                v-show="codeImgIndex == 3"
              />
            </div>
          </div>
        </div>
      </div>
      <div @click="consultShow = true">
        <!-- <a-icon type="message" /> -->
        <a-icon type="edit" />
        <span>我要咨询</span>
      </div>
      <div @click="toTop" v-if="showBtn">
        <a-icon type="vertical-align-top" />
        <span>回到顶部</span>
      </div>
    </div>
    <consult-form :show="consultShow" v-model="consultShow" />
  </div>
</template>

<script>
let isShow = false;
import VCountDown from "@/components/VCountDown";
import uploadFiles from "@/components/uploadFiles";
import { getConsultCreate } from "@/api/news";
import { sendMobile } from "@/api/common";
import { getCategoryList } from "@/api/solution";

import consultForm from "@/components/consultForm";
export default {
  components: {
    VCountDown,
    uploadFiles,
    consultForm,
  },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      showBtn: false,
      codeImgIndex: 1,
      connectShow: false,
      consultShow: false,
      isCode: true,
      categoryData: [],
      jqrshow: {
        jqr: false,
        jqr_yt: false,
        jqr_zy: true,
        jqr_xz: false,
      },
    };
  },
  mounted() {
    this.loadGetCategoryList();
    window.addEventListener("scroll", this.showbtn, true);
    this.jqrchange();
    setInterval(this.jqrchange, 41800);
  },
  methods: {
    userUrl() {
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userScenes/add`,
        "_blank"
      );
    },
    loadGetCategoryList() {
      getCategoryList("1").then((res) => {
        console.log("categoryData", res);
        this.categoryData = res;
      });
    },
    searchType(id, subid) {
      this.$router.push({
        name: "solution",
        params: { id, subid },
      });
    },
    //动画时间控制
    animation(obj, time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.jqrshow = obj;
          resolve();
        }, time);
      });
    },

    async jqrchange() {
      //眨眼
      let jqr_zybool = {
        jqr_yt: false,
        jqr_zy: true,
        jqr_xz: false,
      };

      //摇头
      let jqr_ytbool = {
        jqr_yt: true,
        jqr_zy: false,
        jqr_xz: false,
      };

      //旋转
      let jqr_xzbool = {
        jqr_yt: false,
        jqr_zy: false,
        jqr_xz: true,
      };

      //眨眼
      await this.animation(jqr_zybool, 0);

      //摇头
      await this.animation(jqr_ytbool, 6900);

      //眨眼
      await this.animation(jqr_zybool, 15200);

      //旋转
      await this.animation(jqr_xzbool, 6900);
    },

    connectShowBtn(is) {
      isShow = is;
      if (!is) {
        setTimeout(() => {
          this.connectShow = isShow;
        }, 100);
      } else {
        this.connectShow = is;
      }
    },
    consultShowBtn() {
      this.consultShow = !this.consultShow;
      this.connectShow = false;
    },
    sendMobileCode() {
      const { contactPhone } = this.form;
      sendMobile(contactPhone).then(() => {
        this.$message.success("发送成功！");
      });
    },
    // 上传
    uploadChange(val) {
      this.form.annex = val.id;
    },
    onSubmit() {
      let { form } = this;
      let _this = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            consultTitle: form.consultTitle,
            consultContent: form.consultDesc,
            contactDetail: form.contactPhone,
            contactPerson: form.contactName,
            consultAttmId: form.annex,
            contactCompany: "",
          };
          getConsultCreate(data).then((res) => {
            _this.$message.success("提交成功！");
            _this.$refs.ruleForm.resetFields();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    solutionBtn(lastId, id) {
      window.open(
        `http://prod.gitok.com/solution?lastId=${lastId}&id=${id}`,
        "_blank"
      );
    },
    toUrl(url) {
      window.open(url, "_blank");
    },
    showbtn() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 900) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    toTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
  },
};
</script>

<style lang="scss" scoped>
.customer:hover {
  .customer-title {
    color: #ea0b06;
  }
}

.home-footer {
  background: rgba(0, 0, 0, 0.85);
  .home-footer-box {
    width: 1264px;
    margin: 0 auto;
    .home-footer-lable {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);
      justify-content: space-between;
      align-items: center;
      height: 120px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        > div {
          display: flex;
          align-items: center;
          > img {
            width: 64px;
            height: 64px;
            margin-right: 16px;
          }
        }
      }
    }
    .home-footer-serve {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);
      justify-content: space-between;
      align-items: center;
      height: 120px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        > div {
          display: flex;
          align-items: center;
          > img {
            width: 36px;
            height: 36px;
            margin-right: 12px;
          }
        }
      }
    }
    .home-footer-link {
      display: flex;
      justify-content: space-between;
      padding: 48px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);
      .home-footer-consult {
        > span {
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          margin-bottom: 20px;
          display: block;
        }
        > div {
          > p {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #fff;
            margin-bottom: 16px;
            > img {
              width: 28px;
              height: 28px;
              margin-right: 14px;
            }
          }
          > div {
            display: flex;
            align-items: center;
            padding-top: 16px;
            > div {
              margin-right: 12px;
              > p {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.65);
                line-height: 20px;
                font-weight: 300;
                margin-bottom: 8px;
                cursor: pointer;
                &:last-child {
                  margin-bottom: 0;
                }
                &:hover {
                  color: #ea0b06;
                }
                > img {
                  margin-right: 4px;
                  width: 16px;
                  height: 16px;
                }
              }
            }
            > img {
              width: 90px;
            }
          }
        }
      }
      .home-footer-link-box {
        display: flex;
        > div {
          margin-right: 90px;
          &:last-child {
            margin-right: 0;
          }
          > span {
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            margin-bottom: 20px;
            display: block;
          }
          > p {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.65);
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 8px;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0;
            }
            &:hover {
              color: #ea0b06;
            }
          }
        }
      }
    }
    .home-footer-copyright {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
      line-height: 24px;
      font-weight: 400;
      > span {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .home-footer-frame {
    position: fixed;
    right: 43px;
    bottom: 88px;
    background: #ffffff;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.07);
    width: 70px;
    padding: 0 8px;
    z-index: 10;
    > img {
      position: absolute;
      width: 120px;
      height: 120px;
      top: -111px;
      left: 50%;
      margin-left: -60px;
    }
    > div {
      cursor: pointer;
      padding: 12px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      &:last-child {
        border-bottom: 0;
      }
      > i {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        display: block;
        margin: 0 auto;
      }
      > span {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 17px;
        display: block;
        margin-top: 6px;
        text-align: center;
      }
      &:hover {
        > i {
          color: #ea0b06;
        }
        > span {
          color: #ea0b06;
        }
      }
    }
  }
  .home-floating {
    position: fixed;
    bottom: 100px;
    right: 16px;
    z-index: 10;
    .home-floating-connect {
      width: 48px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(0, 65, 152, 0.08);
      position: relative;
      margin-bottom: 10px;
      > img {
        position: absolute;
        top: -39px;
        right: 4px;
        z-index: -1;
      }
      > p {
        border-bottom: 1px solid #e8e8e8;
        cursor: pointer;
        padding: 15px;
        text-align: center;
      }
    }
    .home-floating-consult {
      width: 48px;
      height: 130px;
      cursor: pointer;
      position: relative;
      .home-floating-consult-box {
        width: 360px;
        background: #ffffff;
        border: 2px solid #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 65, 152, 0.08);
        position: absolute;
        bottom: 0px;
        right: 56px;
        z-index: 2;
        > img {
          width: 100%;
          display: block;
        }
        > i {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          font-size: 16px;
        }
        > div {
          padding: 8px 14px;
        }
      }
    }
  }
}

.home-floating-connect-box {
  width: 239px;
  // height: 276px;
  box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.07);
  background: #ffffff;
  padding: 24px;
  position: absolute;
  top: -168px;
  right: 92px;
  &::after {
    content: "";
    position: absolute;
    right: -12px;
    bottom: 64px;
    border-top: 6px solid transparent;
    border-left: 6px solid #fff;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
  }
  > div {
    display: flex;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    > div {
      > span {
        display: block;
        margin-bottom: 4px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
      }
      > strong {
        display: block;
        line-height: 12px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
      }
      > p {
        line-height: 12px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
      }
      > img {
        display: block;
        width: 120px;
        height: 120px;
      }
    }
  }
}
.home-footer-frame-wx {
  display: flex;
  align-items: center;
  > div {
    margin-right: 12px;
    > p {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
      margin-bottom: 8px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: #ea0b06;
      }
      > img {
        width: 16px;
        margin-right: 4px;
      }
    }
  }
  > img {
    width: 90px !important;
    height: 90px !important;
  }
}
</style>
<style>
.home-footer-link-anchor {
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.home-footer-link-anchor .ant-anchor-ink {
  display: none;
}
.home-footer-link-anchor > div .ant-anchor-link {
  padding: 0;
}
.home-footer-link-anchor > div .ant-anchor-link > a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 8px;
}
.home-footer-link-anchor > div .ant-anchor-link > a:hover {
  color: #ea0b06;
}
.ant-back-top {
  position: static;
  right: 16px;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 20px;
  background: #fff;
  bottom: 240px;
  box-shadow: 0px 4px 8px 0px rgba(0, 65, 152, 0.08);
}
.home-floating-consult-box .ant-form-item {
  margin-bottom: 14px;
}
.home-floating-consult-box .ant-form-item:last-child {
  margin-bottom: 0;
}
</style>
