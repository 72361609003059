<template>
  <div class="home-layout">
    <home-head/>
    <div class="home-layout-content">
      <router-view :key="$route.fullPath" />
    </div>
    <home-footer />
  </div>
</template>

<script>
import homeHead from "./head.vue";
import homeFooter from "./homeFooter.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    homeHead,
    homeFooter,
  },

};
</script>

<style lang="scss" scoped>
.home-layout {
  background: #f7f8fa;
  min-height: 100%;
  .home-layout-content {
    min-height: 100vh;
  }
}
</style>
